<script setup>
  import { getUserInfo } from './useUpdateUserInfo'
  import CheckBox from '@/components/CheckBox'
  import { getCurrentInstance, onMounted, watch, ref, reactive, defineEmits, defineProps } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['changeLoginMode'])
  const props = defineProps({
    loginType: {
      type: String,
      default: '' // ['passwordLogin': 密码登录, 'codeLogin': 'code登录', 'forgotPassword': '忘记密码', 'register': 注册, 'setPassword': '初始化登录' ]
    },
    isLable: {
      // 是否展示lable
      type: Boolean,
      default: false
    },
    notInline: {
      // 是否内部页面使用
      type: Boolean,
      default: false
    }
  })

  const loginForm = ref()

  const form = reactive({
    mobile: '',
    code: '',
    password: '',
    confirmPassword: '',
    agreeStatus: false
  })

  const data = reactive({
    loading: false,
    codeText: 'Get Code',
    countdownTime: 60, // 倒计时时间
    passType: 'text' // 处理element input 密码类型自动带入密码
  })

  watch(
    () => props.loginType,
    (newVal, oldVal) => {
      if (oldVal !== newVal) {
        resetFrom()
        console.log(newVal, 'newVal')
        form.mobile = newVal === 'forgotPassword' && userStore.userInfo.mobile ? userStore.userInfo.mobile : ''
      }
    }
  )

  const rules = {
    //   /^[1-9]\d{9}$/
    mobile: [
      { required: true, message: 'Please enter your mobile number', trigger: 'blur' },
      { pattern: /^(0\d{10}|\d{10})$/, message: 'Invalid mobile phone number', trigger: 'blur' }
    ],
    code: [{ required: true, message: 'Please Enter Verification Code', trigger: 'blur' }],
    password: [
      { required: true, message: 'Please Enter password', trigger: 'blur' },
      { min: 8, max: 20, message: 'The length is 8 to 20', trigger: 'blur' }
    ],
    confirmPassword: [
      { required: true, message: 'Please confirm password', trigger: 'blur' },
      { min: 8, max: 20, message: 'The length is 8 to 20', trigger: 'blur' }
    ]
  }

  // 隐私协议
  const changeBox = (flag) => {
    form.agreeStatus = flag
  }

  // 重置表单
  const resetFrom = () => {
    form.mobile = ''
    form.code = ''
    form.password = ''
    form.agreeStatus = false
    loginForm.value.clearValidate()
    setTimeout(() => {
      data.passType = 'password'
    }, 1000)
  }

  // 查看隐私协议
  const toPrivacyPolicy = (type) => {
    const { href } = proxy.$router.resolve({
      name: 'PrivacyPolicy',
      query: { type: type }
    })
    window.open(href, '_blank')
  }

  // 获取验证码
  const handleCode = async (e) => {
    // console.log(e.target.innerHTML, '获取code')
    await loginForm.value.validateField('mobile', (valid) => {
      if (valid && e.target.innerHTML === 'Get Code') {
        getCode()
        // startCountdown()
      }
    })
  }

  // 获取验证码
  const getCode = () => {
    data.loading = true
    api.user
      .getCode(form.mobile)
      .then((result) => {
        startCountdown()
      })
      .catch((err) => {
        data.codeText = 'Get Code'
        proxy.$toast(`${err.title}`, { type: 'error' })
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 倒计时
  const startCountdown = () => {
    let count = data.countdownTime
    data.codeText = `${count}s`
    const countdownTimer = setInterval(() => {
      if (count > 0) {
        count--
        data.codeText = count === 0 ? 'Get Code' : `${count}s`
        if (count === 0) {
          clearInterval(countdownTimer)
        }
      }
    }, 1000)
  }

  // 处理登录验证
  const handleLogin = async () => {
    await loginForm.value.validate((valid, fields) => {
      if (valid) {
        if ((props.loginType === 'passwordLogin' || props.loginType === 'codeLogin') && !form.agreeStatus) {
          return handleAgreeStatus()
        }
        console.log('调用登录接口')
        // $emit('saveTheAddress', data.addressData)
        if (props.loginType === 'codeLogin') {
          // CODE登录
          login()
        } else if (props.loginType === 'forgotPassword') {
          // console.log('忘记密码')
          modifyPassword()
        } else if (props.loginType === 'setPassword') {
          // console.log('初始化登录, 设置密码')
          setPassword()
        } else {
          // 密码登录|注册
          passwordLogin()
        }
      }
    })
  }

  // 密码修改
  const modifyPassword = () => {
    // console.log(form, '密码修改')
    data.loading = true
    api.user
      .modifyPassword(form)
      .then((res) => {
        console.log(res, '密码修改成功')
        proxy.$Message.confirm({
          type: 'succeed',
          title: 'Password changed! ',
          msg: 'Your Password has been changed successfully',
          isCancel: false,
          okText: 'Sign In',
          onOk: () => {
            proxy.$router.push({
              path: '/login',
              query: {
                type: 'passwordLogin'
              }
            })
          }
        })
      })
      .catch((err) => {
        proxy.$toast(`${err.title}`, { type: 'error' })
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 设置密码
  const setPassword = () => {
    data.loading = true
    api.user
      .setPassword(form)
      .then((res) => {
        console.log(res, '密码设置成功')
        proxy.$toast(proxy.$t('account.PWSuc'), { type: 'succeed' })
        loginSuccessfully()
      })
      .catch((err) => {
        proxy.$toast(`${err.title}`, { type: 'error' })
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 处理隐私协议提示
  const handleAgreeStatus = () => {
    const element = document.querySelector('.agreement-box')
    element.classList.add('shake')
    const timer = setTimeout(() => {
      element.classList.remove('shake')
      clearTimeout(timer)
    }, 500)
  }

  // 登录
  const login = () => {
    data.loading = true
    api.user
      .login(form)
      .then((res) => {
        console.log(res, '登录成功')
        if (res && res.token && res.userDTO) {
          getUserInfo(res.userDTO.id)
          data.loading = false
          if (res.isPop) {
            // 初始化密码逻辑
            return $emit('changeLoginMode', 'setPassword')
          }
          loginSuccessfully()
        } else {
          console.log('无效登录')
        }
      })
      .catch((err) => {
        proxy.$toast(`${err.title}`, { type: 'error' })
        data.loading = false
      })
  }

  // 密码登录
  const passwordLogin = () => {
    data.loading = true
    api.user
      .passwordLogin(form)
      .then((res) => {
        console.log(res, '登录成功')
        if (res && res.token && res.userDTO) {
          getUserInfo(res.userDTO.id)
          loginSuccessfully()
        } else {
          console.log('无效登录')
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        proxy.$toast(`${err.title}`, { type: 'error' })
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 处理成功之后跳转
  const loginSuccessfully = () => {
    const canGoBack = history.state ? history.state.back : ''
    console.log(canGoBack, 'canGoBack')
    if (canGoBack && !canGoBack.includes('/login') && !canGoBack.includes('/USERINFO')) {
      console.log('登录成功，返回上个页面')
      proxy.$router.back()
    } else {
      console.log('登录成功，返回首页')
      proxy.$router.replace({
        path: '/'
      })
    }
  }

  // 切换login类型
  const changeLoginMode = (type) => {
    data.passType = 'text'
    $emit('changeLoginMode', type)
  }

  onMounted(() => {
    setTimeout(() => {
      data.passType = 'password'
    }, 1000)
    form.mobile = props.loginType === 'forgotPassword' && userStore.userInfo.mobile ? userStore.userInfo.mobile : ''
  })
</script>

<template>
  <div class="login-form-box" v-loading="data.loading" :class="props.notInline ? 'notInline-box' : ''">
    <el-form ref="loginForm" :model="form" :rules="rules" @keydown.native.enter="handleLogin" label-width="auto">
      <el-form-item prop="mobile" :label="props.isLable ? 'Phone No' : ''" class="form-input-item" v-if="props.loginType !== 'setPassword'">
        <el-input
          class="phone-input"
          size="large"
          v-model="form.mobile"
          oninput="value=value.replace(/[^\d]/g,'')"
          :disabled="userStore.userInfo.userId && props.loginType === 'forgotPassword' ? true : false"
          maxlength="11"
          placeholder="Please Enter Mobile No"
          clearable
        >
          <template #prepend> +92 </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="code" :label="props.isLable ? 'OTP' : ''" class="form-input-item" v-if="props.loginType !== 'passwordLogin' && props.loginType !== 'setPassword'">
        <div class="rowCC wh100">
          <el-input v-model="form.code" size="large" oninput="value=value.replace(/[^\d]/g,'')" maxlength="6" placeholder="Enter Verification Code" />
          <div class="code-btn rowCC" @click="handleCode">{{ data.codeText }}</div>
        </div>
      </el-form-item>
      <el-form-item prop="password" :label="props.isLable ? 'New Password' : ''" class="form-input-item" v-if="props.loginType !== 'codeLogin'">
        <div class="rowCC wh100 from-item">
          <el-input
            v-model="form.password"
            size="large"
            :type="data.passType"
            minlength="8"
            maxlength="20"
            placeholder="Please Enter password"
            :show-password="form.password.length > 0"
            autocomplete="new-password"
          />
          <div class="forget-password" v-if="props.loginType === 'passwordLogin'" @click="changeLoginMode('forgotPassword')">Forget password ?</div>
        </div>
      </el-form-item>
      <el-form-item
        prop="confirmPassword"
        :label="props.isLable ? 'Confirm Password' : ''"
        class="form-input-item"
        v-if="props.loginType === 'forgotPassword' || props.loginType === 'setPassword'"
      >
        <div class="rowCC wh100">
          <el-input
            v-model="form.confirmPassword"
            size="large"
            :type="data.passType"
            minlength="8"
            maxlength="20"
            placeholder="Confirm Password"
            :show-password="form.confirmPassword.length > 0"
            autocomplete="new-password"
          />
        </div>
      </el-form-item>
      <check-box
        v-model="form.agreeStatus"
        @modelValue="changeBox"
        class="agreement-box"
        v-if="props.loginType === 'passwordLogin' || props.loginType === 'codeLogin' || props.loginType === 'register'"
      >
        <div class="agreement-text-box rowSC wh100">
          &nbsp;{{ $t('l agree to the') }}&nbsp;
          <span class="underline" @click.stop="toPrivacyPolicy('TERMS_CONDITIONS')">{{ $t('Terms of Service') }}</span>
          &nbsp;{{ $t('and') }}&nbsp;
          <span class="underline" @click.stop="toPrivacyPolicy('USER_PRIVACY')"> {{ $t('Privacy Policy') }}</span>
        </div>
      </check-box>
      <el-button class="login-btn wh100" size="large" @click="handleLogin">
        {{ props.loginType === 'passwordLogin' || props.loginType === 'codeLogin' ? 'Sign In' : 'Confirm' }}
      </el-button>
      <div class="rowCC skip-text" v-if="props.notInline === false && props.loginType === 'setPassword'" @click="loginSuccessfully">skip</div>
      <div class="rowBC other-operations" v-if="props.loginType === 'passwordLogin' || props.loginType === 'codeLogin'">
        <div>
          Don’t have an account?
          <span class="underline register-text" @click="changeLoginMode('register')">Register now</span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<style lang="less" scoped>
  /* :deep(.el-input--large) {
    height: 62px !important;
    font-size: 16px;
  }
  :deep(.el-button--large) {
    height: 62px !important;
  } */

  /* el-input__wrapper */
  :deep(.el-input__wrapper) {
    border-radius: 6px !important;
    padding: 6px 12px;
  }

  /* phone-input 类下的 el-input__wrapper */
  :deep(.phone-input .el-input__wrapper) {
    border-radius: 0px 6px 6px 0px !important;
  }

  /* el-form-item__label-wrap */
  :deep(.el-form-item__label-wrap) {
    align-items: center;
  }

  .login-form-box {
    width: 100%;
    margin-top: 32px;
    /* font-size: 24px; */
    .agreement-text-box {
      margin-left: 3px;
      color: #666;
      font-size: 14px;
      span {
        color: var(--btnBgColor);
        cursor: pointer;
      }
    }

    .login-btn {
      width: 100%;
      height: 48px;
      margin-top: 48px;
      color: rgba(255, 255, 255, 1);
      background-color: var(--btnBgColor);
      /* font-size: 24px; */
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      border: none;
      outline: none;
    }

    .skip-text {
      cursor: pointer;
      /* font-size: 24px; */
      font-size: 20px;
      color: var(--btnBgColor);
    }

    .other-operations {
      margin-top: 32px;
      font-size: 20px;
      color: #666;
      cursor: pointer;
      .register-text {
        color: var(--btnBgColor);
      }
    }
    .code-btn {
      width: 120px;
      height: calc(100% - 2px);
      margin: 0px 0 0px 10px;
      border-radius: 6px;
      text-align: center;
      color: var(--btnBgColor);
      border: 1px var(--btnBgColor) solid;
      box-sizing: border-box;
      cursor: pointer;
    }

    .agreement-box {
      margin-top: 32px;
    }
  }

  .notInline-box {
    .login-btn {
      width: 120px;
      margin-left: 132px;
      border-radius: 50px;
    }
  }

  .shake {
    animation: shake 0.5s;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }

    20% {
      transform: translateX(-5px);
    }

    40% {
      transform: translateX(5px);
    }

    60% {
      transform: translateX(-5px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX(0);
    }
  }

  .from-item {
    position: relative;
    .forget-password {
      position: absolute;
      right: 0;
      bottom: -30px;
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }

  .underline {
    text-decoration: underline;
  }
</style>
